<template>
    <div class="cart-item" :key="key">
        <div class="cart-item__top">
            <div class="cart-item__img">
                <!--todo CMS: img always has to be raw html in picture tag (Thumbnail: cart-item-img) -->

                <a :href="this.product.productLink" v-if="this.product.productLink">
                    <figure class="grid-item-magazine__img ratio ratio-1x1" v-html="product.img"></figure>
                </a>
                <figure v-else class="grid-item-magazine__img ratio ratio-1x1" v-html="product.img"></figure>
                <div v-if="this.product.imgDiscountBadge" class="cart-item__img-badge">{{ this.product.imgDiscountBadge }}</div>
            </div>

            <div class="cart-item__description">
                <a v-if="this.product.productLink" :href="this.product.productLink" class="strong cart-item-item__title">{{ product.name }}</a>
                <span v-else class="strong cart-item-item__title">{{ product.name }}</span>

                <div class="cart-item__size-select" v-if="product.size && product.size.length > 0">
                    <select class="form-select" id="size-select" name="size-select" @change="changeSize">
                        <option v-for="(size, index) in product.size" :key="index" :value="size.value" :selected="size.isSelected">{{ size.name }}</option>
                    </select>
                </div>

                <div :class="[
                (product.infoState === 'danger') ? 'cart-item-info-text cart-item-info-text--danger' : '',
                (product.infoState === 'success') ? 'cart-item-info-text cart-item-info-text--success' : '',
                (product.infoState === 'warning') ? 'cart-item-info-text cart-item-info-text--warning' : '',
                ]">
                    {{ product.info }}
                </div>
            </div>

            <button type="button" class="cart-item__delete icon icon-delete d-md-none" @click="removeFromCartItems"></button>
        </div>

        <div class="cart-item__bottom"  :class="this.product.hideNumberSpinner ? 'cart-item__bottom--no-number-spinner' : ''">
            <div class="cart-item__amount">
                <div class="cart-item__number-spinner" v-if="!this.product.hideNumberSpinner">
                    <button @click="decrementAmount" class="btn btn-no-styling" :class="{ disabled: buttonDisabled }">
                        <span class="icon icon-minus"></span>
                        <span class="visually-hidden">decrement amount of Product</span>
                    </button>

                    <span class="cart-item__number-spinner-number">{{ product.amount }}</span>
                    <span class="visually-hidden">amount</span>

                    <button @click="incrementAmount" class="btn btn-no-styling" :class="{ disabled: buttonDisabled }">
                        <span class="icon icon-plus"></span>
                        <span class="visually-hidden">increment amount of Product</span>
                    </button>
                </div>
                <div v-else>
                    <span class="cart-item__number-spinner-number">{{ product.amount }}</span>
                    <span class="visually-hidden">amount</span>
                </div>

                <CartItemProgressBar
                    :discount-text="this.product.discountText"
                    :progress-bar-percentage="percentage">
                </CartItemProgressBar>
            </div>

            <div class="cart-item__prices">
                <div class="cart-item__single-price">
                    <span class="visually-hidden">singlePrice</span>
                    {{ formatPrice(product.singlePrice, this.getLang(), 'currency', currency, currencyFormat) }}

                    <span class="cart-item__default-price" v-if="product.defaultPrice !== product.singlePrice">
                        {{ formatPrice(product.defaultPrice, this.getLang(), 'currency', currency, currencyFormat) }}
                    </span>
                </div>

                <div class="cart-item__total-price">
                    <span class="visually-hidden">totalPrice</span>
                    <span class="strong" aria-hidden="true">
                        {{ formatPrice(product.totalPrice, this.getLang(), 'currency', currency, currencyFormat) }}
                    </span>

                    <span class="visually-hidden">taxInfo</span>
                    <span class="cart-item__tax-info" aria-hidden="true">{{product.taxInfo}}</span>
                </div>
            </div>

            <button type="button" class="cart-item__delete icon icon-delete d-none d-md-block" @click="removeFromCartItems"></button>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import { assert, object, number, string, array, boolean, integer, optional } from "superstruct";
import {throwAlertMessage} from "../../../utils/utils";
import {getCartItemsConfig} from "../../../utils/config-helper";
import {translate} from '@elements/translations';
import CartItemProgressBar from "./CartItemProgressBar.vue";
import {find} from '@elements/dom-utils';
import {resultTracking} from "@elements/tracking";

const products = array(object())
const changeSizeOfProduct = object({
    productId : number(),
    articleNumber : string(),
    maxAmount: integer(),
    img: string(),
    singlePrice : number(),
    defaultPrice : number(),
    info : optional(string()),
    infoState : optional(string()),
    rerenderCart : optional(boolean()),
    deleteCart : optional(boolean()),
    totalAmount: number(),
    shippingCosts: number(),
    amountShippingCalculation: number(),
    discountText: string(),
    discountAmount: number(),
    imgDiscountBadge: string(),
    voucher: optional(number()),
});

const itemsInCart = object({
    cartCounter : number(),
});

const changeAmountOfItemsInCart = object({
    productId : number(),
    articleNumber : string(),
    info : optional(string()),
    infoState : optional(string()),
    totalAmount: number(),
    amountShippingCalculation: number(),
    shippingCosts: number(),
    deleteCart : optional(boolean()),
    discountText: string(),
    discountAmount: number(),
    imgDiscountBadge: string(),
    voucher: optional(number()),
});


export default {
    components: {
        CartItemProgressBar
    },
    props: {
        id: Number,
        cartItemProduct: {},
        currency: '',
        currencyFormat: '',
        key: String,
        hideNumberSpinner: {
            type: Boolean,
            default: false
        }
    },
    watch:{
        cartItemProduct: {
            deep: true
        }
    },
    data(){
        return{
            products: [],
            product: this.cartItemProduct,
            lang: this.getLang(),
            percentage: 0,
            buttonDisabled: false,
        }
    },
    mounted() {
        this.calculatePercentage();
    },
    methods:{
        calculatePercentage() {
            this.percentage = this.product.amount / this.product.discountAmount * 100
        },
        getLang(){
            const _config = window._config || {};
            let lang = _config.langShort;
            return lang;
        },
        //has to be async, otherwise ++ happens after update product --> totalProducts then won't be correct
        async decrementAmount(){
            if(this.product.amount > 1){
                this.buttonDisabled = true;
                let url = getCartItemsConfig().changeAmountOfItemsInCart;
                await axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    params: {
                        articleNumber: this.product.articleNumber,
                        amount: (this.product.amount - 1),
                    } })
                    .then((response) => response.data)
                    .then(response => {
                        try{
                            assert(response.data, changeAmountOfItemsInCart);
                        }catch (error){
                            let errorText = `<p><strong>change amount of product Response has wrong data type</strong></p>`;
                            throwAlertMessage('danger', 'wrong-data-types', errorText + "<br>" + error, true);
                        }
                        this.product.amount--;
                        this.product.info = response.data.info;
                        this.product.infoState = response.data.infoState;
                        this.product.discountAmount = response.data.discountAmount;
                        this.product.discountText = response.data.discountText;
                        this.product.imgDiscountBadge = response.data.imgDiscountBadge;
                        this.voucher = response.data.voucher ? response.data.voucher : 0;
                        this.calcPrice();
                        this.calculatePercentage();
                        this.$emit('setVoucher', this.voucher);
                        this.$emit('decrementAmount', this.product.singlePrice);
                        this.$emit('setShippingCosts', response.data.shippingCosts);
                        this.$emit('setTotalAmount', response.data.totalAmount);
                        this.$emit('setAmountShippingCalculation', response.data.amountShippingCalculation);
                        this.buttonDisabled = false;

                        this.$emit('deleteCart', response.data.deleteCart);

                        if (response){
                            resultTracking(response);
                        }else{
                            console.warn('No Tracking Result');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.buttonDisabled = false;
                    });
            }
      },
        async incrementAmount(){
            if(this.product.amount < this.product.maxAmount){
                let url = getCartItemsConfig().changeAmountOfItemsInCart;
                this.buttonDisabled = true;
                await axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    params: {
                        articleNumber: this.product.articleNumber,
                        amount: (this.product.amount + 1),
                    } })
                    .then((response) => response.data)
                    .then(response => {
                        try{
                            assert(response.data, changeAmountOfItemsInCart);
                        }catch (error){
                            let errorText = `<p><strong>change amount of product Response has wrong data type</strong></p>`;
                            throwAlertMessage('danger', 'wrong-data-types', errorText + "<br>" + error, true);
                        }
                        this.product.amount++;
                        this.product.info = response.data.info;
                        this.product.infoState = response.data.infoState;
                        this.product.discountAmount = response.data.discountAmount;
                        this.product.discountText = response.data.discountText;
                        this.product.imgDiscountBadge = response.data.imgDiscountBadge;
                        this.voucher = response.data.voucher ? response.data.voucher : 0;
                        this.calcPrice();
                        //this.$emit('cart-item-products-change', this.product);
                        this.$emit('incrementAmount', this.product.singlePrice);
                        //this.$emit('calcTotalAmount');
                        this.$emit('setShippingCosts', response.data.shippingCosts);
                        this.$emit('setTotalAmount', response.data.totalAmount);
                        this.$emit('setAmountShippingCalculation', response.data.amountShippingCalculation);
                        this.$emit('setTotalProducts');
                        this.$emit('setVoucher', this.voucher);

                        this.$emit('deleteCart', response.data.deleteCart);

                        this.calculatePercentage();
                        this.buttonDisabled = false;
                        if (response){
                            resultTracking(response);
                        }else{
                            console.warn('No Tracking Result');
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.buttonDisabled = false;
                    });

            }
        },
        formatPrice(amount, locale, style, currency, currencyDisplay, currencyWrapperTagName){
            let string = amount.toLocaleString(locale, {
                style:style || 'decimal',
                currencyDisplay:currencyDisplay || 'symbol',
                currency:currency
            });
            if (currencyWrapperTagName){
                const stringCurrency = string.replace(/(\,|\.|\d)+/g, '').trim();
                string = string.replace(stringCurrency, '<'+currencyWrapperTagName+'>'+stringCurrency+'</'+currencyWrapperTagName+'>');
            }
            return string;
        },
        removeFromCartItems(){
            this.$emit('remove', this.product);
            let url = getCartItemsConfig().itemsInCart;
            axios.get(url)
                .then((response) => response.data)
                .then(response => {
                    try{
                        assert(response.data, itemsInCart);
                    }catch (error){
                        let errorText = `<p><strong>remove items from cart Response has wrong data type</strong></p>`;
                        throwAlertMessage('danger', 'wrong-data-types', errorText + "<br>" + error, true);
                    }

                })
                .catch(error => {
                    console.log(error);
                });
        },
        calcPrice(){
            this.product.totalPrice = (this.product.singlePrice * this.product.amount);
        },
        changeSize(evt){
            let url = getCartItemsConfig().changeSizeOfProduct;
            this.product.size.forEach((size) => {
                size.isSelected = size.value === evt.target.value;
            });
            let selectedItem = this.product.size.find(size => size.isSelected === true);

            if(selectedItem){
                axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    params: {
                        articleNumber: this.product.articleNumber,
                        selectedSize: selectedItem.value
                    }
                })
                    .then((response) => response.data)
                    .then(response => {
                    try{
                        if(!response.data.rerenderCart){
                            assert(response.data, changeSizeOfProduct);
                        }
                    }catch (error){
                        let errorText = `<p><strong>change Size of Product Response has wrong data type</strong></p>`;
                        throwAlertMessage('danger', 'wrong-data-types', errorText + "<br>" + error, true);
                    }

                    if(response.data.rerenderCart){
                        this.$emit('rerenderCart');
                        this.$emit('getInitialCartItems', false, true);
                        this.$emit('setShippingCosts', response.data.shippingCosts);
                        this.$emit('setTotalAmount', response.data.totalAmount);
                    }else{
                        this.rerenderCart = false;
                        this.product.singlePrice = response.data.singlePrice;
                        this.product.info = response.data.info;
                        this.product.infoState = response.data.infoState;
                        this.product.defaultPrice = response.data.defaultPrice;
                        this.product.maxAmount = response.data.maxAmount;
                        this.product.img = response.data.img;
                        this.product.articleNumber = response.data.articleNumber;
                        this.product.discountAmount = response.data.discountAmount;
                        this.product.discountText = response.data.discountText;
                        this.product.imgDiscountBadge = response.data.imgDiscountBadge;
                        this.voucher = response.data.voucher ? response.data.voucher : 0;
                        this.calcPrice();
                        this.$emit('setVoucher', this.voucher);
                        this.$emit('setShippingCosts', response.data.shippingCosts);
                        this.$emit('setTotalAmount', response.data.totalAmount);
                        this.$emit('setAmountShippingCalculation', response.data.amountShippingCalculation);
                        this.$emit('deleteCart', response.data.deleteCart);
                    }
                }).catch(error => {
                    console.log(error);
                });
            }
        },
        translate,
    },
}
</script>

<style scoped>
</style>