import axios from "axios";
import {on, findIn, find, hasClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";


export function init () {
    onFind(".js-ajax-form-add-to-cart", function (form) {
        let button = findIn(".js-count-cart-items", form);
        if(button) {
            let url = button.dataset.cartItemsResponseUrl;
            on('success.ajax-form', (evt) => {
                updateCartCounter(url)
            }, form);
        }

    });

    window.onpageshow = function(event) {
        if (event.persisted) {
            let button = find(".js-shop-cart-counter__wrapper");
            let url = button.dataset.cartItemsResponseUrl;
            if(url) {
                updateCartCounter(url);
                let sidebar = find('.js-cart-sidebar');
                if(hasClass('shown', sidebar)) {
                    removeClass('shown', sidebar);
                }
            }

        }
    }

    onFind(".js-shop-cart-counter__wrapper", function (button) {
        let url = button.dataset.cartItemsResponseUrl;
        if(url) {
            updateCartCounter(url);
        }
    });

    function updateCartCounter(url) {
        axios.get(url)
            .then(function (response) {
                return response;
            })
            .then(function(data) {
                let count = data;
                onFind(".js-shop-cart-counter", function (element) {
                    element.innerHTML = count.data.html;
                });
            })
            .catch(function (error) {
                // Fehler verarbeiten
                console.log(error);
            })
            .finally(function () {
                // Wird immer ausgeführt
            });
    }
}