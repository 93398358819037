<template>

    <div class="loading-overlay">
        <div class="mt-4">
            <div class="loading-spinner {{ className }}" aria-label="{{ label }}">
                <div class="loading-spinner__item loading-spinner__item--1"></div>
                <div class="loading-spinner__item loading-spinner__item--2"></div>
                <div class="loading-spinner__item loading-spinner__item--3"></div>
            </div>
            <div class="mt-3">
                {{ label }}
            </div>
        </div>
    </div>


</template>
<script>
export default {
    props: {
        className: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Loading'
        }
    }
}
</script>