<template>
    <div class="cart-sidebar__shipping-info bg-light">
        <div class="cart-sidebar__shipping-text">
            <span class="icon icon-truck"></span>
            <span v-if="isFreeShipping"><strong>{{ freeShippingReachedText }}</strong></span>
            <span v-else>{{ amountToFreeShippingTextPart1 }} {{ freeShippingAmount }} {{ amountToFreeShippingTextPart2 }} <strong>{{ amountToFreeShippingTextPart3 }}</strong></span>
        </div>

        <div class="cart-sidebar__progress-bar-wrapper">
            <div class="cart-sidebar__progress-bar"></div>
            <div class="cart-sidebar__progress-bar cart-sidebar__progress-bar--filled" :style="{ width : progressBarPercentage + '%' }"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        freeShippingAmount: String,
        progressBarPercentage: Number,
        isFreeShipping: Boolean,
        freeShippingReachedText: String,
        amountToFreeShippingTextPart1: String,
        amountToFreeShippingTextPart2: String,
        amountToFreeShippingTextPart3: String
    },
}
</script>