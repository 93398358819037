<template>
    <div ref="sidebarRef" class="cart-sidebar__wrapper">
        <LoadingSpinner v-if="isLoading"></LoadingSpinner>
        <div v-if="products.length > 0 && showSidebar === true" class="h-100 position-relative" >
            <div class="cart-sidebar__header" >
                <SidebarProgressBar
                    v-if="disableFreeShippingBar === false"
                    :free-shipping-amount="amountTillFreeShipping"
                    :progress-bar-percentage="progressBarPercentage"
                    :is-free-shipping="isFreeShipping"
                    :free-shipping-reached-text="freeShippingReachedText"
                    :amount-to-free-shipping-text-part1="amountToFreeShippingTextPart1"
                    :amount-to-free-shipping-text-part2="amountToFreeShippingTextPart2"
                    :amount-to-free-shipping-text-part3="amountToFreeShippingTextPart3">
                </SidebarProgressBar>

               <div class="cart-sidebar__checkout-btn">
                    <a :href="checkoutLink" v-if="checkoutLink" class="btn btn-no-styling">
                        <span class="cart-sidebar__checkout-btn-text">
                            {{ translate('sidebar.checkout-button') }}
                        </span>
                        <span class="icon icon-arrow-right-long"></span>
                    </a>
               </div>


            </div>

            <div class="cart-sidebar__body" :class="{'has-voucher': this.voucher}">

                <div class="checkout-status-info checkout-status-info--warning" v-if="rerenderSidebar">
                    {{ translate('cart.merge-products') }}
                </div>

                <div class="checkout-status-info checkout-status-info--danger" v-if="cartWillBeDeleted">
                    {{ translate('cart.delete-cart') }}
                </div>
                <CartItem
                    v-for="(cartItemProduct) in products"
                    :cart-item-product="cartItemProduct"
                    :key="cartItemProduct.productId + cartItemProduct.articleNumber + cartItemProduct.amount"
                    :currency="currency"
                    :currencyFormat="currencyFormat"
                    @setTotalAmount="setTotalAmount"
                    @setShippingCosts="setShippingCosts"
                    @remove="removeFromCartItems"
                    @increment-amount="incrementTotalPrice"
                    @decrement-amount="decrementTotalPrice"
                    @rerender-cart="rerenderCart"
                    @delete-cart="removeCart"
                    @set-voucher="setVoucher"
                    @setAmountShippingCalculation="setAmountShippingCalculation"
                    @get-initial-cart-items="getInitialCartItems">
                </CartItem>
            </div>


            <SidebarFooter
                :total-amount="formatPrice(totalAmount)"
                :discount="discount"
                :shipping-costs="shippingCosts"
                :is-free-shipping="isFreeShipping"
                :currency="currency"
                :checkout-link="this.checkoutLink"
                :paypal-link="this.paypalLink"
                :hide-paypal-button="this.hidePaypalButton"
                :voucher="this.voucher"
                @change-loading="this.changeLoading">
            </SidebarFooter>
        </div>

        <div v-else class="h-100">
            <div class="cart-sidebar__body cart-sidebar__body--is-empty" :class="{'has-voucher': this.voucher}">
                <div class="h3">{{ cartEmptyText }}</div>
                <a :href="productOverviewLink" class="btn btn-primary btn-icon">
                    {{ productOverviewLinkText }}
                    <span class="btn-icon__icon icon icon-arrow-right"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import SidebarProgressBar from "../components/cart/SidebarProgressBar.vue";
import CartItem from "../components/cart/CartItem.vue";
import SidebarFooter from "../components/cart/SidebarFooter.vue";
import {getCartItemsConfig} from "../../utils/config-helper";
import {throwAlertMessage} from "../../utils/utils";
import axios from "axios";
import { assert, object, number, string, array, boolean, integer, optional } from "superstruct";
import {translate} from '@elements/translations';
import {initInScope} from '@elements/init-modules-in-scope';
import {useOnClickOutside} from "../../click-outside";
import {find, findAllIn} from '@elements/dom-utils';
import asyncAppend from "@elements/async-append";
import LoadingSpinner from "../components/cart/LoadingSpinner.vue";
import {resultTracking} from "@elements/tracking";




const initialProducts = object({
    successText: string(),
    currency: string(),
    currencyFormat: string(),
    freeShippingAmount: number(),
    discount: optional(number()),
    shippingCosts: optional(number()),
    totalAmount: number(),
    amountShippingCalculation: number(),
    voucher:optional(number()),
    disableFreeShippingBar: optional(boolean()),
    checkoutLink: string(),
    paypalLink: optional(string()),
    cartEmptyText: string(),
    productOverviewLink: string(),
    productOverviewLinkText: string(),
    freeShippingReachedText: string(),
    amountToFreeShippingTextPart1: string(),
    amountToFreeShippingTextPart2: string(),
    amountToFreeShippingTextPart3: string(),
    rerenderCart:optional(boolean()),
    deleteCart:optional(boolean()),
    products: optional(array(object({
        productId : number(),
        articleNumber : string(),
        name : string(),
        img : string(),
        imgDiscountBadge : string(),
        amount : integer(),
        maxAmount: integer(),
        singlePrice : number(),
        defaultPrice : number(),
        totalPrice : number(),
        taxInfo : string(),
        info : optional(string()),
        infoState : optional(string()),
        discountText : optional(string()),
        discountAmount: number(),
        productLink:optional(string()),
        hideNumberSpinner:optional(boolean()),
        size : optional(array(object({
            name: string(),
            value: string(),
            isSelected: boolean()
        }))),
    }))),
});

export default {
    components: {
        LoadingSpinner,
        CartItem,
        SidebarProgressBar,
        SidebarFooter
    },
    props: {
        showSidebar: {
            type: Boolean,
            default: false
        },
        hidePaypalButton: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            totalProducts: 0,
            products: [],
            showModal: false,
            successText: false,
            currency: '',
            currencyFormat: '',
            totalAmount: 0,
            amountShippingCalculation: 0,
            discount: 0,
            shippingCosts: 0,
            formattedTotalAmount: 0,
            isFreeShipping: false,
            freeShippingAmount: 0,
            checkoutLink: '',
            paypalLink: '',
            cartEmptyText: '',
            voucher: 0,
            productOverviewText: '',
            productOverviewLink: '',
            productOverviewLinkText: '',
            freeShippingReachedText: '',
            amountToFreeShippingTextPart1: '',
            amountToFreeShippingTextPart2: '',
            amountToFreeShippingTextPart3: '',
            disableFreeShippingBar: false,
            rerenderSidebar: false,
            cartWillBeDeleted: false,
            isLoading: false,
        }
    },
    computed: {
        amountTillFreeShipping() {
            let cartSum = this.amountShippingCalculation;
            if(cartSum <= this.freeShippingAmount) {
                let price = this.freeShippingAmount - cartSum;
                this.isFreeShipping = false;
                return this.formatPrice(price);
            } else {
                this.isFreeShipping = true;
                return null;
            }
        },
        progressBarPercentage() {
            let cartSum = this.amountShippingCalculation;
            if(cartSum <= this.freeShippingAmount) {
                return cartSum / this.freeShippingAmount * 100;
            } else {
                return 100;
            }
        },
    },
    mounted(){
        this.getInitialCartItems();
    },
    watch: {
        showSidebar(value) {
            if (value === true) {
                this.getInitialCartItems(true, false);
            }
        },
    },
    methods:{
        getLang(){
            const _config = window._config || {};
            let lang = _config.langShort;
            return lang;
        },
        formatPrice(price){
            return new Intl.NumberFormat(this.getLang(), { style: 'currency', currency: this.currency ? this.currency : 'EUR' }).format(price);
        },
        incrementTotalPrice(price) {
            //this.totalAmount += price;
            this.totalProducts += 1;
            this.setCounter(this.totalProducts);
        },
        decrementTotalPrice(price) {
            //this.totalAmount -= price;
            this.totalProducts -=  1;
            this.setCounter(this.totalProducts);
        },
        changeSuccessText(input){
            let url = getCartItemsConfig().changeMailSuccessText;
            axios.get(url, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                params: {
                    email: input,
                }
            });
            this.showModal = false;
            this.successText = true;
        },
        setTotalAmount(totalAmount){
            this.totalAmount = totalAmount;
        },
        setAmountShippingCalculation(amountShippingCalculation){
            this.amountShippingCalculation = amountShippingCalculation;
        },
        setShippingCosts(shippingCosts){
            this.shippingCosts = shippingCosts;
        },
        rerenderCart(){
            this.rerenderSidebar = true;
        },
        removeCart(cartWillBeDeleted = false){
            this.cartWillBeDeleted = cartWillBeDeleted;
        },
        setVoucher(voucher){
            this.voucher = voucher;
        },
        changeLoading(){
            this.isLoading = !this.isLoading;
        },
        getInitialCartItems(closeSidebar = false,  rerenderCart = false){
            let url = getCartItemsConfig().initialCartItems;
            axios
                .get(url)
                .then((response) => response.data)
                .then(response => {
                    try{
                        assert(response.data, initialProducts);
                    }catch (error){
                        console.warn(error);
                        let errorText = `<p><strong>initial cart items has wrong data types</strong></p>`;
                        throwAlertMessage('danger', 'wrong-data-types', errorText + "<br>" + error, true);
                    }
                    this.currency = response.data.currency;
                    this.currencyFormat = response.data.currencyFormat;
                    this.products = response.data.products ? response.data.products : [];
                    this.freeShippingAmount = response.data.freeShippingAmount;
                    this.discount = response.data.discount;
                    this.shippingCosts = response.data.shippingCosts ? response.data.shippingCosts : 0;
                    this.checkoutLink = response.data.checkoutLink;
                    this.paypalLink = response.data.paypalLink ? response.data.paypalLink : null;
                    this.totalAmount = response.data.totalAmount;
                    this.amountShippingCalculation = response.data.amountShippingCalculation;
                    this.cartEmptyText = response.data.cartEmptyText;
                    this.productOverviewLink = response.data.productOverviewLink;
                    this.productOverviewLinkText = response.data.productOverviewLinkText;
                    this.freeShippingReachedText = response.data.freeShippingReachedText;
                    this.amountToFreeShippingTextPart1 = response.data.amountToFreeShippingTextPart1;
                    this.amountToFreeShippingTextPart2 = response.data.amountToFreeShippingTextPart2;
                    this.amountToFreeShippingTextPart3 = response.data.amountToFreeShippingTextPart3;
                    this.disableFreeShippingBar = response.data.disableFreeShippingBar ? response.data.disableFreeShippingBar : false;
                    this.discountText = response.data.discountText ? response.data.discountText : null;
                    this.cartWillBeDeleted = response.data.deleteCart ? response.data.deleteCart : false;
                    this.voucher = response.data.voucher ? response.data.voucher : 0;
                    this.rerenderSidebar = rerenderCart;
                    this.totalProducts = 0;
                    this.products.forEach(product => {
                        this.totalProducts += product.amount;
                    });
                    if(closeSidebar){
                        useOnClickOutside(this.$refs.sidebarRef, () => {
                            let sidebarBtnClose = document.querySelector('.js-open-sidebar--close');
                            sidebarBtnClose.click();
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        voucherRerender(product){
            const selectors = {
                base: '.js-loyalty-card',
                target: '.js-loyalty-card__result',
            };

            let baseElement = document.querySelector(selectors.base);
            if(baseElement){
                let loyaltyURL = baseElement.dataset.loyaltyCardUrl;

                let pendingRequestVoucher = null;

                if (loyaltyURL) {
                    pendingRequestVoucher = axios({method: 'POST', url: loyaltyURL});
                    let results = findAllIn(selectors.target, baseElement);
                    let targetsByResultId = {};

                    results.forEach((element) => {
                        let resultId = element.dataset.loyaltyCardResultId || 'default';
                        if (targetsByResultId[resultId]) {
                            targetsByResultId[resultId] = [...targetsByResultId[resultId], element];
                        } else {
                            targetsByResultId[resultId] = [element];
                        }
                    });

                    asyncAppend({
                        target: targetsByResultId,
                        options: {
                            allowScriptTags: true
                        }
                    }, pendingRequestVoucher.then((x) => {
                        return x.data
                    }));


                } else {
                    console.warn("loyalty-card.js: no loyaltyURL is set");
                }
            }
        },
        removeFromCartItems(product, totalAmount, shippingCosts){
            let url = getCartItemsConfig().deleteProductFromCartItems;
            axios.get(url, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                params: {
                    articleNumber: product.articleNumber,
                }
            })
                .then((response) => response.data)
                .then(
                response => {
                    this.totalProducts -= product.amount;
                    this.setCounter(this.totalProducts);
                    this.totalAmount = totalAmount;
                    this.amountShippingCalculation = response.data.amountShippingCalculation;
                    this.shippingCosts = shippingCosts;
                    this.cartWillBeDeleted = response.data.deleteCart ? response.data.deleteCart : false;
                    this.voucher = response.data.voucher ? response.data.voucher : 0;
                    if(response.data.rerenderVoucherContent){
                        this.voucherRerender(product);
                        this.totalAmount = response.data.totalAmount;
                        this.shippingCosts = response.data.shippingCosts;
                    }
                    if (response){
                        resultTracking(response);
                    }else{
                        console.warn('No Result');
                    }
                }
            ).catch(error => {
                console.log(error);
            }
            );
            let itemToRemove = this.products.find(item => item.articleNumber === product.articleNumber);
            let index = this.products.indexOf(itemToRemove);
            this.products.splice(index, 1);
        },
        setCounter(totalProducts){
            let counter = find('.js-shop-cart-counter');
            counter.innerHTML = totalProducts;
        },
        translate
    },
}
</script>

<style scoped type="scss">

</style>
