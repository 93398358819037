<template>
    <div class="cart-sidebar__footer bg-light">
        <div class="cart-sidebar__prices-wrapper">
            <div v-if="discount > 0" class="cart-sidebar__price-item">
                <span>{{ translate('sidebar.discount') }}</span>
                <span>-{{ formatPrice(discount) }}</span>
            </div>
            <div class="cart-sidebar__price-item" v-if="voucher">
                <span>{{ translate('sidebar.voucher') }}</span>
                <span> {{ formatPrice(voucher) }}</span>
            </div>
            <div class="cart-sidebar__price-item">
                <span>{{ translate('sidebar.shipping') }}</span>
                <span v-if="!isFreeShipping">{{ formatPrice(shippingCosts) }}</span>
                <span v-else>{{ translate('cart.no-shipping-costs') }}</span>
            </div>
            <div class="cart-sidebar__price-item">
                <span class="cart-sidebar__total">{{ translate('sidebar.total') }}
                    <span class="cart-sidebar__taxes">{{ translate('sidebar.tax') }}</span>
                </span>
                <span class="cart-sidebar__total-price strong">{{ totalAmount }}</span>
            </div>
        </div>

        <div class="cart-sidebar__footer-buttons row gy-2">
            <div class="col-md-6 col-12">
                <a :href="checkoutLink" class="btn btn-primary w-100">
                    {{ translate('sidebar.checkout-button') }}
                </a>
            </div>
            <div class="col-md-6 col-12">
                <paypal-button v-if="!hidePaypalButton" @change-loading="changeLoading"></paypal-button>
                <div v-if="!hidePaypalButton" class="cart-sidebar__paypal-description">
                    {{ translate('sidebar.paypal-express-description') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {translate} from '@elements/translations';
import CartItem from "./CartItem.vue";
import SidebarProgressBar from "./SidebarProgressBar.vue";
import PaypalButton from "./PaypalButton.vue";
export default {
    components: {
      PaypalButton
    },
    props: {
        totalAmount: "",
        discount: "",
        shippingCosts: "",
        currency: "",
        checkoutLink: "",
        paypalLink: "",
        voucher: "",
        hidePaypalButton: {
            type: Boolean,
            default: false
        },
        isFreeShipping: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        getLang(){
            const _config = window._config || {};
            let lang = _config.langShort;
            return lang;
        },
        formatPrice(price){
            return new Intl.NumberFormat(this.getLang(), { style: 'currency', currency: this.currency ? this.currency : 'EUR' }).format(price);
        },
        changeLoading(){
            this.$emit('change-loading');
        },
        translate
    }
}
</script>