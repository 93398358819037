"use strict";

import {addClass, removeClass, hasClass, find, trigger, on} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import cartSidebar from "./checkout/templates/CartSidebar.vue";
import tooltipDirective from "./checkout/directives/tooltip";
import {createApp, ref, nextTick} from 'vue';

export function init () {
    onFind('.vue-cart-sidebar', function(element) {
        let vueApp = createApp({
            components: {
                cartSidebar
            },
            setup() {
                let showInitially = element.dataset.showInitially;
                let showSidebar = ref(false);
                const showSidebarInitially = () => {
                    if(showInitially === 'true') {
                        let cartButton = document.querySelector('.js-open-cart-sidebar-initially');
                        cartButton.click();
                    }
                }
                const handleShowClick = (event) => {
                    triggerShowSidebar();
                }

                const handleHideClick = (event) => {
                    triggerHideSidebar();
                }

                const triggerShowSidebar = () => {
                    showSidebar.value= true;
                    let sidebar = find('.js-cart-sidebar');
                    if(!hasClass("shown", sidebar)) {
                        addClass("shown", sidebar);
                        addClass('body--freezed', document.body);
                        trigger('resize', window);
                    }

                    if(!hasClass("is-open", element)) {
                        addClass("is-open", element);
                    }
                }
                const triggerHideSidebar = () => {
                    showSidebar.value = false;
                    let sidebar = find('.js-cart-sidebar');
                    if(hasClass("shown", sidebar)) {
                        removeClass("shown", sidebar);
                        removeClass('body--freezed', document.body);
                        trigger('resize', window);
                    }

                    if(hasClass("is-open", element)) {
                        removeClass("is-open", element);
                    }
                }

                return {
                    showSidebar,
                    showInitially,
                    showSidebarInitially,
                    handleShowClick,
                    handleHideClick,
                    triggerShowSidebar,
                    triggerHideSidebar
                };
            },
            mounted() {
                this.showSidebarInitially();
            }
        });
        tooltipDirective(vueApp);
        vueApp.mount(element);
    });
}