<template>
    <div id="paypal-button-container"></div>
</template>

<script setup>
import {loadScript} from '@paypal/paypal-js';
import {onMounted} from 'vue';
import {getPayPalConfig} from "../../../utils/config-helper";

const props = defineProps({
    reference: Object
});
const emit = defineEmits(['change-loading']);

onMounted(async() => {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;
    const height = isMobile() ? 40 : 48;
    try {
        //disableFunding always has to be set, otherwise paypal buttons are not rendered
        //not very smart, but otherwise disableFundings are not yet set (timing problem)
        let interval = setInterval(() => {
            if(_config.paypalExpressSettings.disableFunding){
                loadScript(_config.paypalExpressSettings)
                    .then((paypal, test) => {
                        paypal.Buttons({
                            style:{
                                shape:   'pill',
                                height:   height,
                                label: 'buynow'
                            },
                            onCancel: function (data) {
                                // e.g. redirect to a certain page or show message
                                window.location.replace(getPayPalConfig().expressOnCancel);
                            },
                            onError: function (error) {
                                window.location.reload();
                            },
                            createOrder: function() {
                                emit('change-loading');
                                return fetch(getPayPalConfig().expressCreateOrder, {
                                    method: 'post',
                                    headers: {
                                        'content-type': 'application/json'
                                    }
                                }).then(function(res) {
                                    return res.json();
                                }).then(function(data) {
                                    return data.id;
                                });
                            },
                            onApprove: function(data) {

                                // make sure you deliver orderID, payerID and paymentID to your
                                // handle response controller action, e.g. by creating a form and
                                // posting the data
                                var form = document.createElement('form');
                                document.body.appendChild(form);
                                form.method = 'POST';
                                form.action = getPayPalConfig().expressOnApprove;

                                var orderID = document.createElement('input');
                                orderID.type = 'hidden';
                                orderID.name = 'orderID';
                                orderID.value = data['orderID'];
                                form.appendChild(orderID);

                                var payerID = document.createElement('input');
                                payerID.type = 'hidden';
                                payerID.name = 'payerID';
                                payerID.value = data['payerID'];
                                form.appendChild(payerID);

                                var paymentID = document.createElement('input');
                                paymentID.type = 'hidden';
                                paymentID.name = 'paymentID';
                                paymentID.value = data['paymentID'];
                                form.appendChild(paymentID);

                                form.submit();
                            }
                        }).render('#paypal-button-container')
                    })
                    .catch((error) => {
                        console.error("failed to load the PayPal JS SDK script", error);
                    })
                clearInterval(interval);
            }
        }, 100);
    } catch (error) {
        console.error(error);
    }
});
</script>

